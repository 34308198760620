import React, { useRef, useEffect, useState, useContext } from 'react';
import { Label } from '../../../../components/elements/UnderscoreLabel';
import { Container, FloatingSaveButton, MainImageArticle, MainImageWrapper } from './Style';
import { H1 } from '../../CreateArticle/Style';
import { Row } from '../../../../components/layouts/Row';
import { CheckIcon } from '../../../../components/inputs/SaveableInput';
import { Input } from '../../../../components/inputs/Input';
import { UploadButton } from '../../../../components/inputs/UploadButton';

import { useImageUpload } from '../../../../components/hooks/article/useImageUpload';
import { useSaveableInput } from '../../../../components/hooks/useSaveableInput';
import { useEditor } from '../../../../components/hooks/useEditorJS1';

import { sendDataToServer } from '../../CreateArticle/requestBodyEditor';
import Button from '../../../../components/elements/Button';
import { GridRow } from '../../../../components/layouts/GridRow';
import { useArticleData } from '../../context/ArticleDataProvider';
import { ImageUpload } from '../../CreateArticle/ImageUpload';
import { AuthContext } from '../../../../app/context/AuthContext';
import { useArticleContext } from '../../../../app/context/ArticleContext';
import { useCategoriesStore } from '../../context/CategoriesProvider';
import status from "../../../../translations/status";
import useUnsavedChangesWarning from '../../../../components/hooks/useSavedForm';

const interfaceTexts = {
  fr: {
    saveButton: 'Enregistrer',
    saving: 'Enregistrement...',
    savedSuccessfully: 'Enregistré avec succès',
    saveError: "Erreur lors de l'enregistrement",
    largeError: "L'article est trop grand pour être enregistré.",
  },
  en: {
    saveButton: 'Save',
    saving: 'Saving...',
    savedSuccessfully: 'Saved successfully',
    saveError: 'Error while saving',
    largeError: 'The article is too large to save.',
  },
};

export const EditArticleForm = ({
  initialValues,
  id,
  formType,
  languageArticle,
  showSaveButtonTop,
  showSaveButtonInHeader,
}) => {
  
  const [categoryStore] = useCategoriesStore((store) => store);
  const [contentOrTitleUpdated, setContentOrTitleUpdated] = useState(false);
  const editorRef = useRef(null);
  const prevLanguage = useRef(languageArticle);

  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  const { setSavedForm } = useUnsavedChangesWarning(appLocale, status);

  // const { article } = useArticleData(id).articleStore

  const [titleTranslations, setTitleTranslations] = useState({
    fr: initialValues.find((t) => t.locale === 'fr')?.title || '',
    en: initialValues.find((t) => t.locale === 'en')?.title || '',
  });

  const [contentTranslations, setContentTranslations] = useState({
    fr: initialValues.find((t) => t.locale === 'fr')?.content || [],
    en: initialValues.find((t) => t.locale === 'en')?.content || [],
  });

  const { editor, isEditorReady, refreshEditorContent } = useEditor({
    editorRef,
    contentTranslations,
    formType,
    languageArticle,
    setContentTranslations,
  });

  const { imageUrl, isUploading, handleImageUpload } = useImageUpload(initialValues[0].image);
  // const { imageUrl, isUploading, handleImageUpload } = useImageUpload(initialValues.find((t) => t.locale === languageArticle).image)

  const [showImageEditIcon, setShowImageEditIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorLarge, setErrorLarge] = useState(false);

  const handleSaveTitle = (newValue) => {
    setTitleTranslations((prevTranslations) => ({
      ...prevTranslations,
      [languageArticle]: newValue,
    }));
  };

  const {
    inputValue,
    setInputValue,
    handleInputChange,
    handleInputFocus,
    handleSaveButtonClick,
    handleKeyDown,
    showSaveButton,
    isEditing,
    setIsEditing,
    inputRef,
  } = useSaveableInput(titleTranslations[languageArticle], handleSaveTitle);

  useEffect(() => {
    const frContent = initialValues.find((t) => t.locale === 'fr')?.content || [];
    const enContent = initialValues.find((t) => t.locale === 'en')?.content || [];

    setContentTranslations({
      fr: frContent,
      en: enContent,
    });

    const frTitle = initialValues.find((t) => t.locale === 'fr')?.title || '';
    const enTitle = initialValues.find((t) => t.locale === 'en')?.title || '';
    setTitleTranslations({
      fr: frTitle,
      en: enTitle,
    });
    setContentOrTitleUpdated(true);
  }, [initialValues]);

  useEffect(() => {
    if (contentOrTitleUpdated) {
      refreshEditorContent();
      setContentOrTitleUpdated(false);
    }
  }, [contentOrTitleUpdated]);

  const handleSave = async (e) => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    try {
      const savedData = await editor.save();

      const updatedContentTranslations = { ...contentTranslations };
      updatedContentTranslations[languageArticle] = savedData.blocks;
      let categories = [];
      categoryStore.categoryInfo.categories.forEach((c) => {
        categories.push(c);
      });

      const result = await sendDataToServer({
        locale: languageArticle,
        translations: updatedContentTranslations,
        titleTranslations,
        image: imageUrl,
        formType,
        id: id,
        categories,
      });

      if (result.success) {
        setLoading(false);
        setSuccess(true);
        setSavedForm(true);
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
      } else if (result.errorMessage === 'Request failed with status code 413') {
        setLoading(false);
        setErrorLarge(true);
        setTimeout(() => {
          setErrorLarge(false);
        }, 4000);
      } else {
        setLoading(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 4000);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4000);
    }
  };

  const handleTitleClick = () => {
    setIsEditing(true);
  };

  return (
    <>
      {showSaveButtonInHeader && (
        <Button onClick={handleSave} style={{ position: 'absolute', right: '0rem', top: '0rem', zIndex: 1 }}>
          {loading && !success && !error && interfaceTexts[appLocale]?.saving}
          {!loading && success && !error && interfaceTexts[appLocale]?.savedSuccessfully}
          {!loading && !success && error && interfaceTexts[appLocale]?.saveError}
          {!loading && !success && errorLarge && interfaceTexts[appLocale]?.largeError}
          {!loading && !success && !error && !errorLarge && interfaceTexts[appLocale]?.saveButton}
        </Button>
      )}
      <GridRow col="2" width="100%">
        {isEditing ? (
          <Input
            name="title"
            type="text"
            value={inputValue}
            onChange={(e) => {
              handleInputChange(e);
              setSavedForm(false);
            }}
            onFocus={handleInputFocus}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            onBlur={() => setIsEditing(false)}
          />
        ) : (
          <H1 onClick={handleTitleClick}>{inputValue}</H1>
        )}
        <ImageUpload imageUrl={imageUrl} handleImageUpload={handleImageUpload} />
      </GridRow>
      <Container>
        <div ref={editorRef} onClick={() => {setSavedForm(false)}} role='textbox' onKeyDown={() => {setSavedForm(false)}}></div>
        {showSaveButtonTop && (
          <FloatingSaveButton onClick={handleSave}>
            {loading && !success && !error && interfaceTexts[appLocale]?.saving}
            {!loading && success && !error && interfaceTexts[appLocale]?.savedSuccessfully}
            {!loading && !success && error && interfaceTexts[appLocale]?.saveError}
            {!loading && !success && !error && interfaceTexts[appLocale]?.saveButton}
          </FloatingSaveButton>
        )}
      </Container>
    </>
  );
};
